import React from "react"
import Helmet from "react-helmet"


function PersonSEO({name, siteUrl, description, imageUrl, email, telefon  }) {
  const lang = "de_DE"
  const metaDescription = description 

return (
    <Helmet
    htmlAttributes={{
      lang,
    }}
    
    title={name}

      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: name,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: imageUrl
        },
        {
          property: `og:url`,
          content: siteUrl
        },
        {
          property: `og:locale`,
          content: `de_DE`
        },
        {
          property: `og:sitename`,
          content: `Proceed GmbH & Co KG`
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Proceed GmbH & Co KG",
        },
        {
          name: `twitter:title`,
          content: name,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat()}
    >

          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org/",
              "@graph": [{
              "@type": "Person",
              "name": "${name}",
              "url": "${siteUrl}",
              "jobTitle": "Consultant",
              "telephone": "${telefon}",
              "email": "${email}",
              "description": "${description}",
              "image": "${imageUrl}"
                },{
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                  "@type": "ListItem",
                  "position": 1,
                  "item": {
                        "@type": "WebPage",
                        "@id": "https://www.proceed.gmbh",
                        "url": "https://www.proceed.gmbh",
                        "name": "Das Experten-Team für Kulturwandel im Unternehmen"
                        }
                    },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@type": "WebPage",
                        "@id": "https://www.proceed.gmbh/team",
                        "url": "https://www.proceed.gmbh/team",
                        "name": "Das Proceed-Team"
                        }
                    },{ 
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@type": "WebPage",
                        "@id": "${siteUrl}",
                        "url": "${siteUrl}",
                        "name": "Proceed -  ${name}"
                        }
                    }]  
                }]
              }
       `}</script>
      
      
    </Helmet>
  )
}



export default PersonSEO
