import React from "react"
import {css} from '@emotion/react';
import {Wrapper, FlexBox, dark, TextBoxHalf, FlexContainerRow, siteheader, orange, background2, Container, background1} from "../styles/globalstyles";
import TeamBloglist from "../components/team/team-bloglist";
import TeamEventlist from "../components/team/team-eventlist";
import PersonSEO from "../SEO/person-seo";

const TeamTemplate = (props) =>  {
    let date= new Date()
    return (
        <>
        <Wrapper background={background2}>
            <Container>
                <PersonSEO 
                    title="Team" 
                    type="Person" 
                    name={props.pageContext.vorname + " " + props.pageContext.nachname} 
                    imageUrl={props.pageContext.portrait.url}
                    description={props.pageContext.motto}
                    siteUrl={"https://proceed.gmbh" + props.path}
                    email={props.pageContext.eMail}
                    telefon={props.pageContext.telefon}
                />
                <FlexContainerRow css={[siteheader,{overflow: "visible",  }]} >
                    <FlexBox direction="column" align="flex-start" css={css` order: 1;`}>
                        <h1 css={{marginBottom: "0.4em !important"}}>{props.pageContext.titel} {props.pageContext.vorname} {props.pageContext.nachname}</h1>
                        <h4 css={{color: orange, marginBottom: "1.6em"}}>{props.pageContext.firmenbeziehung}</h4>
                        <h5>"{props.pageContext.motto}"</h5>
                        <div css={{marginTop: "4em", justifyContent: "flex-start !important", display: "flex", alignItems: "center"}}>
                            <div>
                            <h4 css={{color: dark}}>E-Mail</h4>
                            <a href={"mailto:" + props.pageContext.eMail}><h6 >{props.pageContext.eMail}</h6>  </a> 
                            </div>
                            {props.pageContext.telefon ? 
                            <div css={{marginLeft: "4em"}}>
                            <h4 css={{color: dark}}>Telefon</h4>    
                            <a href={"tel:" + props.pageContext.telefon} ><h6>  {props.pageContext.telefon} </h6></a>  
                            </div>: null}
                        </div>
                        {props.pageContext.firmenname ?  <div>
                            <h4 css={{marginTop: "2em"}}> Eigene Firma:</h4>
                            <a target="_blank" rel="noreferrer" href={props.pageContext.firmenadresse ? props.pageContext.firmenadresse : null}><h6 css={{textDecoration: "underline", '&:hover' : {color: dark} }}>{props.pageContext.firmenname}</h6></a>
                        </div>
                        : null}
                    </FlexBox>
                    <FlexBox direction="column" align="center" css={css`display: none; overflow: visible; order: 2; @media(min-width: 768px) {display: flex} `}>
                        <img alt={props.pageContext.vorname + " " + props.pageContext.nachname} src={props.pageContext.portrait.url} css={css`order:1; border-radius: 2em 0 2em 0; object-fit: cover; box-shadow: 0.4em 0.4em 1.2em -0.4em ${dark}; height: 24em; width: 18em;  `} />
                    </FlexBox>
                </FlexContainerRow>
            </Container>
        </Wrapper>

        <Wrapper background={background1}>
            <Container>
                <FlexContainerRow css={css`min-height: 100vh; align-items: flex-start !important; padding-top: 4em; `} >
                    <FlexBox justify="flex-start" direction="row" align="flex-start" css={css`order: 2; flex-wrap: wrap;`}>
                    <div css={css`order: 2;`}> 
                    {(props.pageContext.daten) ?
                            <TextBoxHalf> 
                                <h4 >Persönliche Daten</h4> 
                                <p>{props.pageContext.daten}</p> 
                            </TextBoxHalf>
                        : null }
                        {(props.pageContext.sinn) ? 
                            <TextBoxHalf> 
                                <h3 >Der Sinn meiner Arbeit</h3> 
                                <p>{props.pageContext.sinn}</p> 
                            </TextBoxHalf>
                        : null }
                        {(props.pageContext.nutzen) ? 
                            <TextBoxHalf> 
                                <h3 >Nutzen für meine Kunden</h3> 
                                <p>{props.pageContext.nutzen}</p> 
                            </TextBoxHalf>
                        : null }
                        {(props.pageContext.faehigkeiten) ?
                            <TextBoxHalf> 
                                <h3 >Meine Fähigkeiten für Ihren Erfolg</h3> 
                                <p>{props.pageContext.faehigkeiten}</p> 
                            </TextBoxHalf>
                        : null }
                    </div>
                    </FlexBox >
                
                        <FlexBox direction="column" css={css`order: 1; margin-bottom: 3em;`}>
                            
                            <div css={css`order: 3;`}> 
                                <TeamEventlist id={props.pageContext.id} date={date}/>
                            </div>
                            <div css={css`order: 4;`}> 
                                <TeamBloglist id={props.pageContext.id}/>
                            </div>
                        </FlexBox>
                </FlexContainerRow>
            </Container>
        </Wrapper>
    </>
)}

export default TeamTemplate;