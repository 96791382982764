export const dateToString = (date) => {
    let datum = new Date(date)
    let day = datum.getDate()
    let month = datum.getMonth() +1 
    let year = datum.getFullYear()
    return day + "." + month + "." + year
}


export const veranstaltungsreiheNextDateHandler = (module) => {
    let vrDateObject = {}
    const today = new Date()
    let moduleArray = [...module]
    moduleArray.sort((a,b) => {
        if ( a.datum < b.datum) return -1
        else if ( a.datum > b.datum) return 1
        else if ( a.datum === b.datum) return 0 
    })

    for (let i = 0; i < moduleArray.length; i++) {
        if (new Date( moduleArray[i].datum) > today) {
            let date = new Date(moduleArray[i].datum)
            vrDateObject = {
                date: date,
                string: date.getDate() + "." + (date.getMonth() +1) + "." + date.getFullYear()
            }
            break 
        }
        
    }
    return vrDateObject
}

export var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  })