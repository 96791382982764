import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { BlogCardSmall } from '../blogcard';
import {css} from '@emotion/react';

const REFERENCES = gql`
query team($id: ID!) 
    {
    team(where: {id: $id}) {
      id
      blogbeitraege(orderBy: datum_DESC, first: 5) {
        id
        titel
        datum
      }
    }
  }
`
const TeamBloglist = ({id}) => {
    const {loading, error, data} = useQuery(REFERENCES, {variables: {id}})
    if (loading) return null
    if (error) return null
    if (data) return (
        <>
        {data.team.blogbeitraege[0] ? <h3 css={css`margin-top: 2.4em; `}> Publikationen</h3> : null}
       { data.team.blogbeitraege.map((value, index) => {
            return <BlogCardSmall props={value} key={index} />

        })}
    </>
    )

}
export default TeamBloglist;