import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { orange } from '../../styles/globalstyles';
import { EventCardSmall, SeminarCardSmall, VortragsreiheCardSmall, WebinarCardSmall } from '../events/eventcard';
import {css} from '@emotion/react';

const REFERENCES = gql`
    query team($id: ID!, $date: DateTime!){
        team(where: {id: $id}) {
            id
            abendveranstaltung(where: {termine_every:{termin_gt: $date}}) {
                id
                titel
                slug
                }
            tagesseminar {
                id 
                titel
                slug
                }
            onlineevent(where: {termine_every:{termin_gt: $date}}) {
                id 
                titel
                slug
                }
            veranstaltungsreihe {
                id
                titel
                slug
            }
            }
        }

 `
const TeamEventlist = ({id, date}) => {
   const {loading, error, data} = useQuery(REFERENCES, { variables: {"id": id, "date": date} } )
    if (loading) return null
    if (error) return null
    if (data) {
        return(
            <>
            {data.team.abendveranstaltung[0] || data.team.tagesseminar[0] || data.team.onlineevent[0] ? <h3>Veranstaltungen</h3> : null }
                {data.team.abendveranstaltung[0] ?  <h4 css={css`margin-top: 2.4em; color: ${orange}; display: none;`}>Abendveranstaltungen</h4> : null }
                {data.team.abendveranstaltung.map((value, index) => {
                    return <EventCardSmall props={value} key={index}  />

                })}
                {data.team.tagesseminar[0] ?  <h4 css={css`margin-top: 2.4em; color: ${orange}; display: none;`}>Tagesseminar</h4> : null }
                {data.team.tagesseminar.map(value => {
                    return <SeminarCardSmall props={value} key={value.id} />

                })}
                {data.team.onlineevent[0] ?  <h4 css={css`margin-top: 2.4em; color: ${orange}; display: none;`}>Online-Event</h4> : null }
                {data.team.onlineevent.map(value  => {
                    return <WebinarCardSmall props={value} key={value.id} />

                })}
                {data.team.veranstaltungsreihe[0] ?  <h4 css={css`margin-top: 2.4em; color: ${orange}; display: none;`}>Online-Event</h4> : null }
                {data.team.veranstaltungsreihe.map(value  => {
                    return <VortragsreiheCardSmall props={value} key={value.id} />

                })}
            
            </>
        )
    }

}
export default TeamEventlist;